import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import GlobalContext from "../context/GlobalContext";
import dayjs from "dayjs";
function CalendarHeader({ text }) {
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);
  const [montss , setMonts]=useState()
  let monts = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthsru = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
 
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }
 
  useEffect(()=>{
    if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[0]
    ) {
      setMonts(monthsru[0]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[1]
    ) {
      setMonts(monthsru[1]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[2]
    ) {
      setMonts(monthsru[2]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[3]
    ) {
      setMonts(monthsru[3]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[4]
    ) {
      setMonts(monthsru[4]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMM") == monts[5]
    ) {
      setMonts(monthsru[5]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[6]
    ) {
      setMonts(monthsru[6]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[7]
    ) {
      setMonts(monthsru[7]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[8]
    ) {
      setMonts(monthsru[8]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[9]
    ) {
      setMonts(monthsru[9]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[10]
    ) {
      setMonts(monthsru[10]);
    } else if (
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM") == monts[11]
    ) {
      setMonts(monthsru[11]);
    }
  },[monthIndex])
  
  return (
    <header className="px-4 py-2 flex items-center ">
      <img src={logo} className="sm:mr-2 h-10 sm:h-12" alt="" />
      <button
        className="border border-gray-500 rounded py-2 px-5 mx-5 hidden sm:block lg:block"
        onClick={handleReset}
      >
        {" "}
        Сегодня{" "}
      </button>
      <button onClick={handlePrevMonth}>
        <span className="material-icons-outlined cursor-pointer hidden sm:block lg:block text-gray-600 mx-2">
          chevron_left
        </span>
      </button>
      <button onClick={handleNextMonth}>
        <span className="material-icons-outlined cursor-pointer hidden sm:block lg:block text-gray-600 mx-2">
          chevron_right
        </span>
      </button>
      <h2 className="hidden sm:block ml-4 text-xl text-gray-600 font-bold mx-2">
        {montss}
      </h2>
    </header>
  );
}

export default CalendarHeader;
