import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { getMonth } from "../util";
import GlobalContext from "../context/GlobalContext";

function SmallCalendar() {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [montss , setMonts]=useState()
  let monts = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthsru = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  useEffect(()=>{
    if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[0]
    ) {
      setMonts(monthsru[0]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[1]
    ) {
      setMonts(monthsru[1]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[2]
    ) {
      setMonts(monthsru[2]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[3]
    ) {
      setMonts(monthsru[3]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[4]
    ) {
      setMonts(monthsru[4]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMM") == monts[5]
    ) {
      setMonts(monthsru[5]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[6]
    ) {
      setMonts(monthsru[6]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[7]
    ) {
      setMonts(monthsru[7]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[8]
    ) {
      setMonts(monthsru[8]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[9]
    ) {
      setMonts(monthsru[9]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[10]
    ) {
      setMonts(monthsru[10]);
    } else if (
      dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM") == monts[11]
    ) {
      setMonts(monthsru[11]);
    }
  },[currentMonthIdx])
  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);
  const { monthIndex, setSmallCalendarMonth, setDaySelected, daySelected } =
    useContext(GlobalContext);
  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);
  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }
  function getDayCalass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected && daySelected.format(format);
    if (nowDay == currDay) {
      return "bg-blue-500 rounded-full text-white";
    } else if (currDay === slcDay) {
      return "bg-blue-100 rounded-full text-blue-500 font-bold";
    } else {
      return "";
    }
  }
  return (
    <div className="mt-9">
      <header className="flex justify-between">
        <p className="text-gray-500 font-bold">
          {montss}
        </p>
        <div>
          {" "}
          <button onClick={handlePrevMonth}>
            <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
              chevron_left
            </span>
          </button>
          <button onClick={handleNextMonth}>
            <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
              chevron_right
            </span>
          </button>
        </div>
      </header>
      <div className="grid grid-cols-7 grid-rows-6">
        {currentMonth[0].map((day, i) => (
          <span key={i} className="text-sm py-1 text-center">
            {day.format("dd").charAt(0)}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setSmallCalendarMonth(currentMonthIdx);
                  setDaySelected(day);
                }}
                className={`py-1 w-full ${getDayCalass(day)}`}
              >
                <span className="text-sm">{day.format("D")}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default SmallCalendar;
